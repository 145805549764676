/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

interface IAppContext {
    phoneNumber: string;
    roles: any[];
    technologies: any[];
}
export const AppContext = React.createContext<IAppContext | undefined>(
    undefined
);
// ToDo: Creat App  context/Provider


export interface AppProviderProps {
    children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = (
    props: AppProviderProps
) => {
    const [phoneNumber, setPhoneNumber] = React.useState<string>("(545) 555-5555")
    const [roles, setRoles] = React.useState<any[]>(["", "Prescribing Doctor", "Management Team", "Assistant","Dental Team Member", "Other"])
    const [technologies, setTechnology] = React.useState<any[]>(["", "We don't have a scanner yet","ITERO", "3SHAPE", "MEDIT", "CARESTREAM", "CEREC", "ICAT", "ITERO & TRIOS", "PANORAMA", "PLANMECA", "SCAN X", "SIRONA", "SPRINTRAY", "TRIOS", "VORTEX"])

    return (
        <AppContext.Provider
            value={{
                phoneNumber,
                roles,
                technologies
            }}>
            {props.children}
        </AppContext.Provider>
    )

}

export const useApp = () => React.useContext(AppContext);
